<template>
    <div
        :class="{
            active: !!modelValue,
            focused: isSearchFocused,
        }"
        class="input-group filter-search flex-grow-1"
        title="[Ctrl + k]"
    >
        <input
            ref="search"
            :disabled="isProcessing"
            :placeholder="placeholder"
            :value="modelValue"
            class="form-control"
            style="background: #fff !important"
            type="text"
            @blur="blurSearchFilter"
            @click="focusSearchFilter"
            @keyup.enter="search"
        />
        <div class="input-group-text bg-primary border-0">
            <spinner v-if="isProcessing && isSearchFocused" />
            <search v-else fill="#fff" />
        </div>
    </div>
</template>
<script>
import Search from '@/../icons/Search.vue';

export default {
    name: 'search-filter',
    components: { Search },
    emits: ['update:modelValue'],
    props: {
        modelValue: String,
        isProcessing: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isSearchFocused: false,
        };
    },
    created() {
        window.addEventListener('keydown', this.keyboardListener);
    },

    destroyed() {
        window.removeEventListener('keydown', this.keyboardListener);
    },
    methods: {
        focusSearchFilter() {
            this.$refs.search.focus();
            this.$refs.search.select();
            this.isSearchFocused = true;
        },
        blurSearchFilter() {
            if (!this.isSearchFocused) {
                return;
            }

            this.isSearchFocused = false;
            if (this.$refs.search === document.activeElement) {
                document.activeElement.blur();
            }
        },
        keyboardListener(event) {
            if (this.isSearchFocused && event.key.toLowerCase() === 'escape') {
                this.blurSearchFilter();
                return;
            }

            if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
                this.focusSearchFilter();
            }
        },
        search() {
            this.$emit(
                'update:modelValue',
                String(this.$refs.search.value).trim(),
            );
        },
    },
};
</script>
