<template>
    <app-layout>
        <asset-details-header @status-check-performed="updateIsAssetOperable" />
        <div class="py-3">
            <row>
                <column md="6">
                    <card>
                        <template #header>
                            <custom-card-header
                                :href="$route('assets.edit', asset.id)"
                                :show-action="
                                    hasAnyPermission([
                                        'asset.update',
                                        'asset.location.update',
                                    ])
                                "
                                :title="__('assets.details.header')"
                                actionIcon="cil-pencil"
                            />
                        </template>

                        <dl class="m-0">
                            <div class="my-1 row">
                                <dt class="col-md-5 text-uppercase">
                                    {{ __('assets.details.label.operability') }}
                                </dt>
                                <dd class="col-md-7 text-muted">
                                    <div class="d-flex" style="gap: 5px">
                                        <tooltip-wrapper
                                            :enabled="
                                                isOperationInProgress ||
                                                !isAssetOnboarded
                                            "
                                            :tooltip="
                                                __(
                                                    'asset.action_disabled_due_to_incomplete_onboarding',
                                                )
                                            "
                                            class="d-inline-block"
                                        >
                                            <asset-operability-switch
                                                v-if="
                                                    hasAnyPermission(
                                                        'asset.update_status',
                                                    )
                                                "
                                                v-model="isAssetOperable"
                                                :disabled="!isAssetOnboarded"
                                                @changed="
                                                    openConfirmAssetStatusChangeModal
                                                "
                                            />
                                            <status-badge
                                                v-else
                                                :operability="assetOperability"
                                                :usage="
                                                    asset.status.usageCategory
                                                "
                                            />
                                        </tooltip-wrapper>
                                        <additional-status-information
                                            :additional-information="
                                                asset.status
                                                    .operabilityChangeAdditionalInformation
                                            "
                                            :reason="
                                                asset.status
                                                    .operabilityChangeReason
                                            "
                                        />
                                    </div>
                                    <status-check-date
                                        :show-decommissioned="false"
                                        :status="asset.status"
                                        class="text-small"
                                    />
                                </dd>
                            </div>
                            <div class="my-1 row">
                                <dt class="col-md-5 text-uppercase">
                                    {{ __('assets.details.label.status') }}
                                </dt>
                                <dd class="col-md-7 text-muted">
                                    <div
                                        v-if="
                                            hasAnyPermission(
                                                'asset.update_status',
                                            )
                                        "
                                    >
                                        <tooltip-wrapper
                                            :enabled="
                                                isOperationInProgress ||
                                                !isAssetOnboarded
                                            "
                                            :tooltip="
                                                __(
                                                    'asset.action_disabled_due_to_incomplete_onboarding',
                                                )
                                            "
                                            class="d-inline-block"
                                        >
                                            <usage-category-selection-dropdown
                                                :disabled="
                                                    isOperationInProgress ||
                                                    !isAssetOnboarded
                                                "
                                                :is-processing="
                                                    isChangingUsageCategory
                                                "
                                                :model-value="
                                                    asset.status.usageCategory
                                                "
                                                :usage-categories="
                                                    usageCategories
                                                "
                                                @update:model-value="
                                                    updateAssetUsageCategory
                                                "
                                            />
                                        </tooltip-wrapper>
                                    </div>
                                    <div v-else>
                                        <b>
                                            {{
                                                currentAssetUsageCategory.label
                                            }}
                                        </b>
                                    </div>
                                    <div
                                        v-if="
                                            hasFeature('asset-status-check') &&
                                            hasAnyPermission(
                                                'asset.status_check.read',
                                            ) &&
                                            asset.status?.decommissionedAt
                                        "
                                    >
                                        <status-check-date
                                            :status="asset.status"
                                            class="text-small"
                                        />
                                    </div>
                                </dd>
                            </div>
                            <div
                                v-for="(value, key) in fields"
                                :key="key"
                                class="my-1 row"
                            >
                                <dt class="col-md-5 text-uppercase">
                                    {{ __('assets.details.label.' + key) }}
                                </dt>
                                <dd class="col-md-7 text-muted">
                                    <span
                                        v-if="
                                            value && typeof value === 'object'
                                        "
                                        :class="value?.classes"
                                        >{{ value.value }}</span
                                    >
                                    <span v-else>{{
                                        value || __('general.not_available')
                                    }}</span>
                                </dd>
                            </div>
                        </dl>
                    </card>
                    <card class="d-none d-md-block">
                        <template #header>
                            <CustomCardHeader
                                :title="__('qa.overview.header')"
                            />
                        </template>

                        <cadence-card-with-modal
                            v-if="hasFeature('asset-status-check')"
                            :configuration="asset.status_checks.configuration"
                            :issues="asset.status_checks.issues"
                            :next-due-date-url="
                                route(
                                    'assets.status-checks.next-due-date',
                                    asset,
                                )
                            "
                            :store-url="
                                route('assets.status-checks.configure', asset)
                            "
                            translation-key="assetstatuschecks"
                            type="asset_status_check"
                        />
                        <cadence-card-with-modal
                            v-if="hasFeature('test-record')"
                            :configuration="asset.tests.configuration"
                            :issues="asset.tests.issues"
                            :next-due-date-url="
                                route('assets.tests.next-due-date', asset)
                            "
                            :store-url="$route('assets.tests.configure', asset)"
                            translation-key="tests"
                            type="test"
                        />

                        <cadence-card-with-modal
                            :configuration="asset.maintenances.configuration"
                            :issues="asset.maintenances.issues"
                            :next-due-date-url="
                                route(
                                    'assets.maintenances.next-due-date',
                                    asset,
                                )
                            "
                            :store-url="
                                route('assets.maintenances.configure', asset)
                            "
                            card-classes="mb-0"
                            translation-key="maintenances"
                            type="maintenance_record"
                        />
                    </card>
                </column>
                <column md="6">
                    <asset-stakeholders-card />
                    <configuration-overview
                        v-if="hasAnyPermission('configuration.read')"
                        :asset="asset"
                        :configurations="configurations"
                        :qualifications="qualifications"
                    />
                    <card class="d-md-none">
                        <template #header>
                            <custom-card-header
                                :title="__('qa.overview.header')"
                            />
                        </template>

                        <cadence-card-with-modal
                            v-if="hasFeature('asset-status-check')"
                            :configuration="asset.status_checks.configuration"
                            :next-due-date-url="
                                route(
                                    'assets.status-checks.next-due-date',
                                    asset,
                                )
                            "
                            :store-url="
                                route('assets.status-checks.configure', asset)
                            "
                            translation-key="assetstatuschecks"
                            type="asset_status_check"
                        />
                        <cadence-card-with-modal
                            v-if="hasFeature('test-record')"
                            :configuration="asset.tests.configuration"
                            :next-due-date-url="
                                route('assets.tests.next-due-date', asset)
                            "
                            :store-url="$route('assets.tests.configure', asset)"
                            translation-key="tests"
                            type="test"
                        />
                        <cadence-card-with-modal
                            :configuration="asset.maintenances.configuration"
                            :next-due-date-url="
                                route(
                                    'assets.maintenances.next-due-date',
                                    asset,
                                )
                            "
                            :store-url="
                                route('assets.maintenances.configure', asset)
                            "
                            card-classes="mb-0"
                            translation-key="maintenances"
                            type="maintenance_record"
                        />
                    </card>
                    <card>
                        <template #header>
                            <custom-card-header
                                :href="
                                    route('assets.edit', asset.id) +
                                    '#attachments'
                                "
                                :show-action="
                                    hasAnyPermission('asset.attachment.create')
                                "
                                :title="__('attachments.overview.header')"
                                actionIcon="cil-cloud-upload"
                            />
                        </template>

                        <file-list
                            :attachments="attachments"
                            :readonly="true"
                        />
                    </card>
                </column>
            </row>
            <row>
                <column
                    class="d-flex align-items-center justify-content-center"
                >
                    <confirmed-delete
                        v-if="hasAnyPermission('asset.delete')"
                        ref="confirmDelete"
                        :buttonTitle="
                            __('assets.details.confirmation.delete_asset.title')
                        "
                        :is-button-disabled="!deleteAssetForm.isDirty"
                        :isProcessing="isOperationInProgress"
                        @onCancel="resetForm()"
                        @onDelete="deleteAsset()"
                    >
                        <template #title>
                            {{
                                __(
                                    'assets.details.confirmation.delete_asset.title',
                                )
                            }}
                        </template>
                        <template #content>
                            <nl2br
                                :text="
                                    __(
                                        'assets.details.confirmation.delete_asset.content',
                                    )
                                "
                            />
                            <jet-text-area
                                v-model="deleteAssetForm.reason"
                                :horizontal="false"
                                :invalidFeedback="deleteAssetForm.errors.reason"
                                :isValid="!deleteAssetForm.errors.reason"
                                :label="
                                    __(
                                        'assets.details.confirmation.delete_asset.reason.label',
                                    )
                                "
                                :placeholder="
                                    __(
                                        'assets.details.confirmation.delete_asset.reason.description',
                                    )
                                "
                                :required="true"
                                :rows="Number(5)"
                                class="mt-3"
                            />
                        </template>
                    </confirmed-delete>
                    <confirm-asset-status-change-modal
                        @cancel="cancelAssetStatusChange"
                    />
                </column>
            </row>
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import formatAsFullDate from '@/filters/fullDate';
import ConfigurationOverview from '@/Components/ConfigurationOverview.vue';
import FileList from '@/Components/FileList.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import ConfirmedDelete from '@/Components/ConfirmedDelete.vue';
import JetTextArea from '@/Jetstream/Textarea.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import CadenceCardWithModal from '@/Components/CadenceCard.vue';
import TooltipWrapper from '@/Pages/Assets/Details/TooltipWrapper.vue';
import ConfirmAssetStatusChangeModal from '@/Pages/Assets/Details/ConfirmAssetStatusChangeModal.vue';
import AssetOperabilitySwitch from '@/Pages/Assets/Details/AssetOperabilitySwitch.vue';
import AdditionalStatusInformation from '@/Components/AdditionalStatusInformation.vue';
import AssetStakeholdersCard from '@/Pages/Assets/Details/AssetStakeholdersCard.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import UsageCategorySelectionDropdown from '@/Pages/Assets/Details/UsageCategorySelectionDropdown.vue';
import Card from '@/Components/Card.vue';
import { eventBus, events } from '@/eventBus.js';
import { PetiteCIdScannerType } from '@/Components/PetitecIdScanner.vue';

export default {
    components: {
        Card,

        UsageCategorySelectionDropdown,
        StatusBadge,
        AssetStakeholdersCard,
        AdditionalStatusInformation,
        AssetOperabilitySwitch,
        ConfirmAssetStatusChangeModal,
        TooltipWrapper,
        CadenceCardWithModal,
        AppLayout,
        ConfigurationOverview,
        FileList,
        AssetDetailsHeader,
        CustomCardHeader,
        ConfirmedDelete,
        JetTextArea,
        StatusCheckDate,
    },

    data() {
        return {
            changes: null,
            showActivityDetails: false,
            showAssetCalibration: false,
            activeTab: 0,
            isOperationInProgress: false,
            isUpdateAssetStatusInProgress: false,
            isConfirmAssetStatusChangeInProgress: false,
            deleteAssetForm: this.$inertia.form({
                reason: null,
            }),
            isAssetOperable: null,
            isChangingUsageCategory: false,
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },

        assetOperability() {
            return this.isAssetOperable ? 'operable' : 'inoperable';
        },

        attachments() {
            return this.$page.props.attachments?.data;
        },

        configurations() {
            return this.$page.props.configurations;
        },

        qualifications() {
            return this.$page.props.qualifications.data;
        },

        currentAssetUsageCategory() {
            let usageCategory = this.usageCategories.find(
                (el) => el.value === this.asset.status.usageCategory,
            );
            if (this.isAssetOnboarded && usageCategory) {
                return usageCategory;
            }

            return { label: this.__('assets.status.onboarded'), value: null };
        },

        usageCategories() {
            return this.$page.props.usageCategories.data;
        },

        location() {
            let {
                country_code_alpha2,
                updatedAt,
                icao_code,
                terminal,
                physical_location_name,
                name,
                timezone,
            } = this.asset.location;

            terminal = terminal ? 'T' + terminal : null;
            let airport = physical_location_name + ' (' + icao_code + ')';
            const icon = country_code_alpha2
                ? `cif-${country_code_alpha2.toLowerCase()}`
                : null;

            return {
                name: [airport, terminal, name]
                    .filter((item) => item)
                    .join(' - '),
                icon: icon,
                timezone,
                updatedAt: updatedAt,
            };
        },

        assetLocalTimezone() {
            return this.asset.location.timezone.identifier;
        },

        fields() {
            const notAvailable = this.__('general.not_available');
            return {
                criticality: this.asset.critical
                    ? this.__('assets.details.field.criticality.yes')
                    : this.__('assets.details.field.criticality.no'),
                model: this.asset.model.designation || notAvailable,
                screening_application:
                    this.asset.screening_application?.label || notAvailable,
                type: this.asset.type.label || notAvailable,
                manufacturer: this.asset.manufacturer.name,
                serial_number: this.asset.serial_number || notAvailable,
                purchased_at: formatAsFullDate(this.asset.purchased_at, 'en'),
                deployed_at: formatAsFullDate(this.asset.deployed_at, 'en'),
                petitec_identifier:
                    this.asset.petitec_identifier || notAvailable,
                location: this.getLocationInfo(),
                specific_location: this.asset.location?.name || notAvailable,
                customer_tracking_number: this.asset.customer_tracking_number,
                warranty: this.getWarrantyInfo(),
            };
        },

        isAssetOnboarded() {
            return !!this.asset.petitec_identifier;
        },
    },

    mounted() {
        this.updateIsAssetOperable();
        this.startScannerIfNecessary();
    },

    methods: {
        deleteAsset() {
            this.isOperationInProgress = true;
            this.deleteAssetForm.delete(route('assets.destroy', this.asset), {
                onFinish: () => {
                    this.isOperationInProgress = false;
                },
            });
        },
        updateAssetUsageCategory(usageCategory) {
            if (usageCategory === this.asset.status.usageCategory) {
                return;
            }

            this.isOperationInProgress = true;
            this.isChangingUsageCategory = true;

            this.$inertia.post(
                this.route('assets.update-usage-category', this.asset),
                { usageCategory },
                {
                    preserveState: true,
                    onFinish: () => {
                        this.isOperationInProgress = false;
                        this.isChangingUsageCategory = false;
                    },
                },
            );
        },
        resetForm() {
            this.deleteAssetForm.reset();
            this.deleteAssetForm.clearErrors();
        },
        cancelAssetStatusChange() {
            this.updateIsAssetOperable();
        },
        updateIsAssetOperable() {
            switch (this.asset.status.operability) {
                case 'operable':
                    this.isAssetOperable = true;
                    break;
                case 'inoperable':
                    this.isAssetOperable = false;
                    break;
                default:
                    this.isAssetOperable = null;
            }
        },
        getWarrantyInfo() {
            let startDate = formatAsFullDate(
                this.asset.warranty?.start_date,
                this.currentLocale,
            );
            let endDate = formatAsFullDate(
                this.asset.warranty?.end_date,
                this.currentLocale,
            );

            if (!startDate && !endDate) {
                return this.__('general.not_available');
            }

            if (startDate && endDate) {
                return `${startDate} - ${endDate}`;
            }

            if (startDate) {
                return (
                    this.__('assets.details.label.warranty_start_date') +
                    ' ' +
                    startDate
                );
            }

            if (endDate) {
                return (
                    this.__('assets.details.label.warranty_end_date') +
                    ' ' +
                    endDate
                );
            }
        },
        getLocationInfo() {
            if (!this.asset.location) {
                return this.__('general.not_available');
            }

            return `${this.asset.location.iata_code} - ${this.asset.location.physical_location_name}`;
        },
        openConfirmAssetStatusChangeModal() {
            eventBus.$emit(events.openConfirmAssetStatusChangeModal, {
                assetId: this.asset.id,
                operability: this.assetOperability,
            });
        },
        startScannerIfNecessary() {
            if (!!this.asset.petitec_identifier || !this.route().params.scan) {
                return;
            }

            eventBus.$emit(events.openQrCodeScanner, {
                assetId: this.asset.id,
                type: PetiteCIdScannerType.attachPetiteCId,
                onSuccessfulScan: () => {
                    this.$inertia.reload();
                },
            });
        },
    },
};
</script>
<style>
.popover-old {
    z-index: 999 !important;
}

.popover-no-header + .popover-old > .popover-old-header {
    display: none;
}
</style>
